import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import LinkButton from './LinkButton';
import { Button } from '@mui/material';

const MaterialModal = ({ open, handleClose }) => {
  const [modalWidth, setModalWidth] = useState(400);

  useEffect(() => {
    const updateWidth = () => {
      setModalWidth(window.innerWidth < 400 ? window.innerWidth * 0.9 : 400);
    };

    // Set the initial width
    updateWidth();

    // Add event listener for window resize
    window.addEventListener('resize', updateWidth);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: modalWidth,
    bgcolor: '#1e1e1e',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            sx={{
              textAlign: 'center',
              marginBottom: 2,
              fontWeight:'bold',
              color:'white'
            }}
          >
            Upcoming Events
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <Button
              variant="contained"
              sx={{
                background: 'white',
                color: 'black',
                width: '100%',
                ':hover': {
                  background: 'linear-gradient(45deg, #A09ADC 20%, rgba(183, 232, 207, 1) 60%)',
                  fontWeight:'bold'
                },
              }}
              onClick={() => window.open('https://linktr.ee/kemeproduction?utm_source=linktree_profile_share', '_blank')}
            >
              Canada & USA Tour<br/>@kemeproduction tickets
            </Button>
            <Button
              variant="contained"
              sx={{
                background: 'white',
                color: 'black',
                width: '100%',
                ':hover': {
                  background: 'linear-gradient(45deg, #A09ADC 20%, rgba(183, 232, 207, 1) 60%)',
                  fontWeight:'bold'                
                },
              }}
              onClick={() => window.open('https://www.easykash.net/IVD9314', '_blank')}
            >
              Ras Sudr<br/>Soul Sounds - Winter Edition<br/>By La Hacienda Rassudr
            </Button>
            <Button
              variant="contained"
              sx={{
                background: 'white',
                color: 'black',
                width: '100%',
                ':hover': {
                  background: 'linear-gradient(45deg, #A09ADC 20%, rgba(183, 232, 207, 1) 60%)',
                  fontWeight:'bold'                
                },
              }}
              onClick={() => window.open('https://etathkara.com/en/OvergroundNYE25?fbclid=PAZXh0bgNhZW0CMTEAAaZM5zsZOvUUEPjQsVSCGxOtBx4O-HapBndUMzRTgWGyBlwoLPP_ItANMiE_aem_uDn6jYtx8c1C2Ydw_UB6zQ', '_blank')}
            >
              NYE @Overground<br/>Jordan Tickets
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
  
};

export default MaterialModal;
