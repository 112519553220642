// frames.js
const frames = [
    require('../assets/gifFrames/frame1.png'),
    require('../assets/gifFrames/frame2.png'),
    require('../assets/gifFrames/frame3.png'),
    require('../assets/gifFrames/frame4.png'),
    require('../assets/gifFrames/frame5.png'),
    require('../assets/gifFrames/frame6.png'),
    require('../assets/gifFrames/frame7.png'),
    require('../assets/gifFrames/frame8.png'),
    require('../assets/gifFrames/frame9.png'),
    require('../assets/gifFrames/frame10.png'),
    require('../assets/gifFrames/frame11.png'),
    require('../assets/gifFrames/frame12.png'),
    require('../assets/gifFrames/frame13.png'),
    require('../assets/gifFrames/frame14.png'),
    require('../assets/gifFrames/frame15.png'),
    require('../assets/gifFrames/frame16.png'),
    require('../assets/gifFrames/frame17.png'),
    require('../assets/gifFrames/frame18.png'),
    require('../assets/gifFrames/frame19.png'),
    require('../assets/gifFrames/frame20.png'),
    require('../assets/gifFrames/frame21.png'),
    require('../assets/gifFrames/frame22.png'),
    require('../assets/gifFrames/frame23.png'),
    require('../assets/gifFrames/frame24.png'),
    require('../assets/gifFrames/frame25.png'),
    require('../assets/gifFrames/frame26.png'),
    require('../assets/gifFrames/frame27.png'),
];

  
  export default frames;
  
