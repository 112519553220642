export const Conditions = [
    { threshold: 100, divisor: 800 },
    { threshold: 105, divisor: 775 },  // New condition
    { threshold: 110, divisor: 750 },
    { threshold: 115, divisor: 725 },  // New condition
    { threshold: 120, divisor: 700 },
    { threshold: 125, divisor: 675 },  // New condition
    { threshold: 130, divisor: 650 },
    { threshold: 135, divisor: 625 },  // New condition
    { threshold: 140, divisor: 600 },
    { threshold: 145, divisor: 575 },  // New condition
    { threshold: 150, divisor: 550 },
    { threshold: 155, divisor: 525 },  // New condition
    { threshold: 160, divisor: 500 },
    { threshold: 165, divisor: 475 },  // New condition
    { threshold: 170, divisor: 450 },
    { threshold: 175, divisor: 425 },  // New condition
    { threshold: 180, divisor: 400 },
    { threshold: 185, divisor: 375 },  // New condition
    { threshold: 190, divisor: 350 },
    { threshold: 195, divisor: 325 },  // New condition
    { threshold: 200, divisor: 300 },
    { threshold: 205, divisor: 275 },  // New condition
    { threshold: 210, divisor: 250 },
    { threshold: 215, divisor: 225 },  // New condition
    { threshold: 220, divisor: 200 },
    { threshold: 225, divisor: 175 },  // New condition
    { threshold: 230, divisor: 150 },
  ];