import React from 'react'
import Release1 from '../releases/release1.png';
import Release2 from '../releases/release2.png';
import Release3 from '../releases/release3.png';
import Release4 from '../releases/release4.png';
import Release5 from '../releases/release5.png';
import Release6 from '../releases/release6.png';
import Release7 from '../releases/release7.png';
import Release8 from '../releases/release8.png';
import Release9 from '../releases/release9.png';

export const ReleasesData = [
    {
    },
    
    {
      img: Release1,
      title: "Khamsa Disco Bel Hob",
      links: {
        spotify: "https://spotify.com/khamsa",
        anghami: "https://anghami.com/khamsa",
        youtube: "https://youtube.com/khamsa",
        appleMusic: "https://apple.com/music/khamsa"
      }
    },
    {
      img: Release2,
      title: "Ahla Wahda",
      links: {
        spotify: "https://spotify.com/ahla",
        youtube: "https://youtube.com/ahla"
      }
    },
    {
      img: Release3,
      title: "Disco Misr x Adidas",
      links: {
        spotify: "https://spotify.com/adidas",
        anghami: "https://anghami.com/adidas",
        youtube: "https://youtube.com/adidas"
      }
    },
    {
      img: Release4,
      title: "Aga2eb El Donia El 7",
      links: {
        spotify: "https://spotify.com/aga2eb",
        appleMusic: "https://apple.com/music/aga2eb"
      }
    },
    {
      img: Release5,
      title: "El Album Abl Abl El Akhir",
      links: {
        spotify: "https://spotify.com/ablabl",
        anghami: "https://anghami.com/ablabl",
        youtube: "https://youtube.com/ablabl",
        appleMusic: "https://apple.com/music/ablabl"
      }
    },
    {
      img: Release6,
      title: "Keify Keda",
      links: {
        spotify: "https://spotify.com/keify",
        youtube: "https://youtube.com/keify"
      }
    },
    {
      img: Release7,
      title: "Moshakes",
      links: {
        spotify: "https://spotify.com/moshakes",
        anghami: "https://anghami.com/moshakes",
        appleMusic: "https://apple.com/music/moshakes"
      }
    },
    {
      img: Release8,
      title: "Kaset Trip",
      links: {
        spotify: "https://spotify.com/kaset",
        youtube: "https://youtube.com/kaset"
      }
    },
    {
      img: Release9,
      title: "El Fat Mat",
      links: {
        spotify: "https://spotify.com/elfatmat",
        anghami: "https://anghami.com/elfatmat",
        appleMusic: "https://apple.com/music/elfatmat"
      }
    },
    {}
  ];