import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    isVisible && (
      <button 
        onClick={scrollToTop} 
        style={{
          position: 'fixed',
          bottom: '50px',
          right: '50px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '10px',
          fontSize: '16px',
          backgroundColor: 'white',
          color: 'black',
          border: 'none',
          borderRadius: '50%',
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          cursor: 'pointer',
          zIndex: 1000,
          transition: 'all 0.3s ease',
          width: '60px',
          height: '60px',
          boxShadow: '0 10px 20px rgba(0, 0, 0, 0.3), 0 6px 6px rgba(0, 0, 0, 0.2)',
        }}
        onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = '#black';
            e.currentTarget.style.color = '#00000080';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = 'white';
            e.currentTarget.style.color = '#000';
          }}
          
      >
        <FontAwesomeIcon icon={faArrowUp} size="lg" />
        <span style={{ fontSize: '12px', marginTop: '5px', fontWeight:400 }}>Return</span>
      </button>
    )
  );
};

export default BackToTop;
