import React, { useEffect, useRef, useState } from 'react';
import './stylesheets/About.css';
import About1 from './assets/About1.png';
import About2 from './assets/About2.png';
import About3 from './assets/About3.png';
import About4 from './assets/About4.webp';
import VideoFile from './assets/DiscoVid.mp4'; // Replace with your video file
import VideoFileGif from './assets/DIscoVidGif.gif'; // Replace with your video file
import frames from './components/Frames';

export default function About() {
  const about2Ref = useRef(null);
  const videoRef = useRef(null);
  const aboutSectionsRef = useRef(null);
  const lastScrollY = useRef(0);
  const ticking = useRef(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);
  const speedMultiplier = 2.75; // Adjust this value to control the speed
  const [currentFrame, setCurrentFrame] = useState(0);
  const totalFrames = frames.length; // Total number of frames
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkWindowWidth = () => {
      setIsWideScreen(window.innerWidth > 768);
    };

    window.addEventListener('resize', checkWindowWidth); // Check on window resize

    return () => {
      window.removeEventListener('resize', checkWindowWidth); // Cleanup listener on unmount
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!ticking.current) {
        window.requestAnimationFrame(() => {
          const aboutPage = document.getElementById('AboutPage');
          const aboutPageHeight = aboutPage.offsetHeight;
          const scrollY = window.scrollY;
          const video = videoRef.current;
          const videoDuration = video.duration;

          if (videoDuration) {
            const scrollFraction = Math.min(scrollY * 2.75 / aboutPageHeight, 1);
            video.currentTime = scrollFraction * videoDuration;
          }

          if (scrollY >= aboutPageHeight && lastScrollY.current < aboutPageHeight) {
            aboutSectionsRef.current.scrollIntoView({ behavior: 'smooth' });
          }

          lastScrollY.current = scrollY;
          ticking.current = false;
        });

        ticking.current = true;
      }
    };

    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          about2Ref.current.src = About4;
        } else {
          about2Ref.current.src = About2;
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5
    });

    if (about2Ref.current) {
      observer.observe(about2Ref.current);
    } else {
      console.warn("about2Ref.current is not an HTML element");
    }
    
    // For The About Small div so no conflicts happen when you scroll down
    if (windowWidth > 768) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      observer.disconnect();
    };
  }, [windowWidth]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
      const scrollFraction = scrollTop / maxScroll;
      const frameIndex = Math.min(totalFrames - 1, Math.floor(scrollFraction * totalFrames * speedMultiplier));

      setCurrentFrame(frameIndex);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [totalFrames]);

  useEffect(() => {
    const mediaElements = [
      About1, About2, About3, About4, VideoFile, VideoFileGif, ...frames
    ];

    let loadedCount = 0;

    mediaElements.forEach(src => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        loadedCount += 1;
        if (loadedCount === mediaElements.length) {
          setIsLoading(false);
        }
      };
      img.onerror = () => {
        loadedCount += 1;
        if (loadedCount === mediaElements.length) {
          setIsLoading(false);
        }
      };
    });
  }, []);

  return (
    <div id='AboutWrapper'>
          <div id='AboutPage' style={{
            display:`${windowWidth < 768 ? 'none' : ''}`
          }}>
            <video ref={videoRef} className='reviving-video' src={VideoFile} muted style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width: '105%', position: 'absolute', }} />
          </div>
          <div id='AboutSmall' style={{
            display:`${windowWidth < 768 ? 'flex' : 'none'}`, backgroundColor:'black', 
            height: '133svh', // Extend the height to ensure there's enough scroll space
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'center',
          }}>
            <img src={frames[currentFrame]} style={{ width: '120%', height:'auto', objectFit:'cover', objectPosition:'center top'}} alt="Animated Frame"/>
          </div>
          <div id='AboutSections' ref={aboutSectionsRef}>
            <div id='PartSection'>
              <h4>Disco Misr is an Egyptian electronic music group based in Cairo, Egypt. Formed in 2014 and fully established in 2016, the group is composed of two DJs and one VJ (visual jockey). Their musical style focuses on reviving classics with funk and disco genres while infusing a modern twist.</h4>
              <img src={About1} alt="About 1" />
            </div>
            <div id='PartSection'>
              <img ref={about2Ref} src={About2} alt="About 2" />
              <h4>Through memorable live performances featuring both audio and visual elements, Disco Misr aims to provide cultural experiences that engage audiences. Since completing the band's formation in 2016, the trio has embarked on numerous tours across Egypt and the region. With each new live show, the group refines their chemistry and performance abilities, resulting in an act that continues to evolve and improve.</h4>
            </div>
            <div id='PartSection'>
              <h4>Blending elements of retro soul and dance genres with modern production, Disco Misr preserves what made the original songs great while updating them for contemporary listeners. Whether playing for crowds of thousands or in intimate club settings, their goal is to get audiences moving to infectious grooves that transcend generations. Through their music and performances, Disco Misr celebrates cultural traditions with a rebellious spirit.</h4>
              <img src={About3} alt="About 3" />
            </div>
          </div>
    </div>
  );
  
}
