import React, { useEffect, useState } from 'react';
import './stylesheets/StudioPics.css';
import StudioPic1 from './studiopics/StudioPic1.png';
import StudioPic2 from './studiopics/StudioPic2.png';
import StudioPic3 from './studiopics/StudioPic3.png';
import StudioPic4 from './studiopics/StudioPic4.png';
import StudioPic5 from './studiopics/StudioPic5.png';
import StudioPic6 from './studiopics/StudioPic6.png';
import StudioPic7 from './studiopics/StudioPic7.png';
import StudioPic8 from './studiopics/StudioPic8.png';
import StudioPic9 from './studiopics/StudioPic9.png';
import StudioPic10 from './studiopics/StudioPic10.png';
import StudioPic11 from './studiopics/StudioPic11.png';
import StudioPic112 from './studiopics/StudioPic11_2.png';
import StudioPic12 from './studiopics/StudioPic12.png';
import StudioPic13 from './studiopics/StudioPic13.png';
import CenterLogo from './studiopics/centerlogo.png';

export default function StudioPictures() {
    const [scrollY, setScrollY] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const transitionRatio = Math.min(scrollY / 1000, 10); // Adjust value to control the speed of transition
    let val = 0.25
    let rotationAngle;
    
    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };
        
        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    
    if(windowWidth<768){
        rotationAngle = scrollY * 0.70; // Adjust this value to control the speed of rotation
    }
    else {
        rotationAngle = scrollY * 0.25;
    }
    
    if(rotationAngle > 350){
        rotationAngle = 360
    }
    const bigPicTransform = windowWidth < 768 ? '' : `scale(${1 - transitionRatio * 0.70}) translate(${transitionRatio * -1700}px, ${transitionRatio * 1175}px)`;

    return (
        <div id='StudioSection' style={{ marginBottom: '1%' }}>
            {/* <h3 style={{position:'fixed', fontSize:64}}>transitionRatio: {transitionRatio}</h3> */}
            <div id='StudioWrapper'>
                <img
                    id='BigPic'
                    src={StudioPic1}
                    alt='Studio Session with Disco Misr'
                    style={{
                        transform: bigPicTransform,
                        opacity: `${1 - transitionRatio}`,
                        transition: 'transform 0.3s ease, opacity 0.3s ease',
                    }}
                />
                <img id='CenterLogo' src={CenterLogo} style={{ position: 'absolute', zIndex: 9998, width: 125, top: 150 }} alt='Disco Misr Logo' />
            </div>
            <div>
                <div className="row">
                    <div className="column">
                        <img 
                            src={StudioPic1} 
                            alt='Studio Session with Disco Misr' 
                            style={{
                                opacity: `${transitionRatio}`,
                                transition: 'opacity 0.3s ease',
                                display:`${windowWidth<768? 'none': ''}`
                            }}
                        />
                        <img src={StudioPic4} alt='Studio Session with Disco Misr' />
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '4%' }} alt='Studio Session with Disco Misr'>
                            <img src={StudioPic7} style={{ width: '48%', height: '50%' }} alt='Studio Session with Disco Misr' />
                            <img src={StudioPic8} style={{ width: '48%', height: '50%' }} alt='Studio Session with Disco Misr' />
                        </div>
                            <img src={StudioPic9} alt='Studio Session with Disco Misr' />
                        <img
                            src={transitionRatio > 2.2 ? StudioPic112 : StudioPic11}
                            style={{ opacity: `${1 - transitionRatio * 0.1}`, transition: 'opacity 0.3s ease' }}
                            alt='Studio Session with Disco Misr'                        
                        />
                        <img src={StudioPic3} alt='Studio Session with Disco Misr'  style={{
                                display:`${windowWidth>768? 'none': ''}`
                        }}/>

                    </div>

                    <div className="column">
                    <img src={StudioPic3} alt='Studio Session with Disco Misr'  style={{
                                display:`${windowWidth<768? 'none': ''}`
                        }}/>
                        <img src={StudioPic5} alt='Studio Session with Disco Misr' />
                        <img src={StudioPic12} alt='Studio Session with Disco Misr' style={{}}/>
                        <img 
                            src={StudioPic6} 
                            alt='Studio Session with Disco Misr'
                            style={{
                                transform: `rotate(${rotationAngle}deg)`,
                                transition: 'transform 0.3s ease',
                                display:`${windowWidth>768? 'none': ''}`
                            }}
                        />
                         <img src={StudioPic13} alt='Studio Session with Disco Misr' style={{
                                display:`${windowWidth>768? 'none': ''}`
                        }} 
                        />
                    </div>

                    <div className="column">
                        <img src={StudioPic2} alt='Studio Session with Disco Misr' />
                        <img 
                            src={StudioPic6} 
                            alt='Studio Session with Disco Misr'
                            style={{
                                transform: `rotate(${rotationAngle}deg)`,
                                transition: 'transform 0.3s ease',
                                display:`${windowWidth<768? 'none': ''}`
                                
                            }}
                        />
                        <img src={StudioPic10} alt='Studio Session with Disco Misr' />
                        <img src={StudioPic13} alt='Studio Session with Disco Misr' style={{
                                display:`${windowWidth<768? 'none': ''}`
                        }} 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
