import { createContext, useContext, useEffect, useState } from "react";
import Client from 'shopify-buy'
import { publicShopifyToken, shopifyDomain } from "../config.js";

const client = Client.buildClient({
    domain: shopifyDomain,
    storefrontAccessToken: publicShopifyToken,
});

const ShopContext = createContext()

export function ShopProvider({ children }) {
    /** @type {ReturnType<typeof useState<Client.Product[]>>} */
    const [products, setProducts] = useState([])
    /** @type {ReturnType<typeof useState<Client.Checkout>>} */
    const [checkout, setCheckout] = useState()

    function fetchProducts() {
        client.product.fetchAll().then((result) => {
            setProducts(result);
        }).catch(error => console.log(error));
    }

    async function fetchProductDetails(globalProductID) {
        try {
            return await client.product.fetch(globalProductID)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     *  @param {string} variantId
     *  @param {number} quantity 
     *  @returns {boolean} `true` if successful, `false` otherwise
     */
    async function addToCart(variantId, quantity = 1) {
        try {
            let curCheckout = checkout
            console.log(curCheckout)
            if (!curCheckout) {
                curCheckout = await client.checkout.create()
                console.log('storing...')
                localStorage.setItem('checkoutID', curCheckout.id)
                setCheckout(curCheckout)
            }

            curCheckout = await client.checkout.addLineItems(curCheckout.id, [
                { variantId, quantity }
            ])

            console.log(curCheckout)
            setCheckout(curCheckout)
            return true;
        } catch (error) {
            console.error(error)
            return false
        }
    }

    useEffect(() => {
        fetchProducts()
        localStorage.getItem('checkoutID') && client.checkout.fetch(localStorage.getItem('checkoutID')).then(setCheckout)
    }, [])
    return (
        <ShopContext.Provider value={{
            products, checkout,
            fetchProductDetails, addToCart
        }}>
            {children}
        </ShopContext.Provider>
    )
}

/**
 * 
 * @returns {{
 * products: Client.Product[]
 * checkout: Client.Checkout
 * fetchProductDetails(globalProductID: string): Promise<Client.Product | null>
 * addToCart(variantId: string, quantity?: number): Promise<boolean>
 * }}
 */
export function useShopContext() {
    return useContext(ShopContext)
}