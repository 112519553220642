import React, { useEffect, useState } from 'react';
import '../stylesheets/LinkButton.css';
import { logEvent } from "firebase/analytics";
import { analytics } from "../../Firebase";

export default function LinkButton({ link, text, left, id, textcolor, onClick }) {
  const [topPosition, setTopPosition] = useState('80%');
  let lastScrollTop = 0;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;

      if (currentScrollTop > lastScrollTop && currentScrollTop > 200) {
        // After scrolling down 200px, move to -30%
        setTopPosition('-30%');
      } else if (currentScrollTop <= 200) {
        // Scroll down/up behavior between 80% and 20%
        setTopPosition(`${Math.max(20, 80 - (currentScrollTop / window.innerHeight) * 60)}%`);
      }

      lastScrollTop = currentScrollTop;
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleClick = () => {
    logEvent(analytics, "button_press", { button_name: "gmusiclink-tul8te" });
  };

  const combinedOnClick = (event) => {
    handleClick();
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <a
      onClick={combinedOnClick}
      href={link}
      target='_blank'
      rel='noopener noreferrer'
      id={id}
      style={{ position: 'absolute', top: topPosition, transition: 'top 0.3s ease', left: left, cursor: 'pointer', WebkitUserSelect:'none'}}
    >
      <h4 style={{ color: textcolor }} id='LinkButtonText'>{text}</h4>
    </a>
  );
}
