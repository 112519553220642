import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Pages/stylesheets/HamburgerNav.css';

export default function HamburgerNav() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isLivePictures = location.pathname === '/LivePictures';
  const isReleases = location.pathname === '/Releases';
  const isHome = location.pathname === '/';
  const isBooking = location.pathname === '/bookings';
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const checkWindowWidth = () => {
      setIsWideScreen(window.innerWidth > 768);
    };

    window.addEventListener('resize', checkWindowWidth); // Check on window resize

    return () => {
      window.removeEventListener('resize', checkWindowWidth); // Cleanup listener on unmount
    };
  }, []);

  const isConditionMet = isWideScreen || isLivePictures || isReleases || isHome || isBooking;
  const condtionsClose = isLivePictures || isReleases;

  const handleColorChange = `${isLivePictures || isReleases || isHome || isBooking ? 'white' : 'black'}`

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleBookingsClick = () => {
    navigate('/bookings');
    toggleMenu();
  };

  return (
    <nav className="HamburgerNav">
      <div
        className={`hamburger ${isLivePictures || isHome || isBooking ? 'live-pictures' : ''} ${isReleases ? 'releases' : ''} ${isOpen ? 'open' : ''}`}
        onClick={toggleMenu}
      >
        {isOpen ? (
          <div
            className={`close-icon ${isConditionMet ? 'white' : 'black'}`}
            style={{ paddingRight: 20, paddingBottom: 20, color: condtionsClose ? 'black': '' }}
            onClick={toggleMenu}
          >
            X
          </div>) : (
          <>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </>
        )}
      </div>
      <div className={`menu ${isOpen ? 'open' : ''}`} style={{ backgroundColor: `${isLivePictures || isReleases || isHome || isBooking ? 'black' : 'white'}` }}>
        <Link style={{ color: handleColorChange }} to="/" onClick={toggleMenu}>HOME</Link>
        <Link style={{ color: handleColorChange }} to="/about" onClick={toggleMenu}>ABOUT</Link>
        <Link style={{ color: handleColorChange }} to="/LivePictures" onClick={toggleMenu}>LIVE PHOTOS</Link>
        <Link style={{ color: handleColorChange }} to="/StudioPictures" onClick={toggleMenu}>STUDIO PHOTOS</Link>
        <Link style={{ color: handleColorChange }} to="/Releases" onClick={toggleMenu}>RELEASES</Link>
        <a style={{ cursor: 'pointer', color: handleColorChange }} onClick={handleBookingsClick}>BOOKINGS</a>
        {/* <Link style={{ color: handleColorChange }} to="/Shop" onClick={toggleMenu}>Shop</Link> */}

      </div>
    </nav>
  );
}
