import React, { useEffect, useRef, useState } from 'react';
import './stylesheets/LivePictures.css';
import LivePic1 from './assets/LivePics1.png';
import LivePic2 from './assets/LivePics2.png';
import LivePic3 from './assets/LivePics3.png';
import LivePic4 from './assets/LivePics4.png';
import LivePic5 from './assets/LivePics5.webp';
import LivePic6 from './assets/LivePics6.webp';
import LivePic7 from './assets/LivePics7.webp';
import LivePic8 from './assets/LivePics8.webp';
import LivePic9 from './assets/LivePics9.webp';
import LivePic10 from './assets/LivePics10.webp';
import LivePic11 from './assets/LivePics11.webp';
import ScrollToTop from './components/ScrollToTop';

const ThumbnailNavigation = ({ images, activeIndex, onThumbnailClick }) => {
  return (
    <div className="thumbnail-wrapper">
      {images.map((image, index) => (
        <div
          key={index}
          className={`thumbnail ${index === activeIndex-1 ? 'active' : ''}`}
          style={{ backgroundImage: `url(${image})` }}
          onClick={() => onThumbnailClick(index+1)}
        ></div>
      ))}
    </div>
  );
};

export default function LivePictures() {
  const wrapperRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const images = [LivePic1, LivePic2, LivePic3, LivePic4, LivePic5, LivePic6, LivePic7, LivePic8, LivePic9, LivePic10, LivePic11];

  
  useEffect(() => {
    const handleScroll = () => {
      const wrapper = wrapperRef.current;
      const sectionWidth = wrapper.clientWidth;
      const newIndex = Math.round(wrapper.scrollLeft / sectionWidth);
      const currentScrollLeft = wrapper.scrollLeft;
      const maxScroll = wrapper.scrollWidth - wrapper.clientWidth;
      const scrollProgress = currentScrollLeft / maxScroll;
      const moveDistance = scrollProgress * 3000; // Adjust distance based on scroll progress
      
      setActiveIndex(newIndex);
      
      const words = wrapper.querySelectorAll('.word');
      words.forEach((word, index) => {
        const angle = index * (360 / words.length);
        const radians = (angle * Math.PI) / 90;
        const x = (Math.cos(radians) * moveDistance) * 1.25;
        const y = (Math.sin(radians) * moveDistance) * 1.25;
    
        // Adjust opacity to start disappearing 5% after scroll
        if (scrollProgress > 0.05) {
          word.style.opacity = Math.max(1 - (scrollProgress - 0.05) * 2, 0);
        } else {
          word.style.opacity = 1;
        }
    
        word.style.transform = `translate(${x*3}px, ${y*3}px)`;
      });
    };

    const wrapper = wrapperRef.current;
    wrapper.addEventListener('scroll', handleScroll);
    return () => {
      wrapper.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const startDrag = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - wrapperRef.current.offsetLeft);
    setScrollLeft(wrapperRef.current.scrollLeft);
  };

  const onDrag = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - wrapperRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    wrapperRef.current.scrollLeft = scrollLeft - walk;
  };

  const stopDrag = () => {
    setIsDragging(false);
  };

  const scrollToLeft = () => {
    const newIndex = Math.max(activeIndex - 1, 0);
    setActiveIndex(newIndex);
    const sectionWidth = wrapperRef.current.clientWidth;
    wrapperRef.current.scrollTo({ left: sectionWidth * newIndex, behavior: 'smooth' });
  };

  const scrollToRight = () => {
    const newIndex = Math.min(activeIndex + 1, images.length - 1);
    setActiveIndex(newIndex);
    const sectionWidth = wrapperRef.current.clientWidth;
    wrapperRef.current.scrollTo({ left: sectionWidth * newIndex, behavior: 'smooth' });
  };

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
    const sectionWidth = wrapperRef.current.clientWidth;
    wrapperRef.current.scrollTo({ left: sectionWidth * index, behavior: 'smooth' });
  };

  return (
    <div
      id='LivePicturesWrap'
      ref={wrapperRef}
      onMouseDown={startDrag}
      onMouseMove={onDrag}
      onMouseUp={stopDrag}
      onMouseLeave={stopDrag}
    >
      <div className="section">
        <div className="word">OUR</div>
        <div className="word">LIVE</div>
        <div className="word">PICTURES</div>
        {images.length > 0 && (
          <div className="next-image-overlay" onClick={scrollToRight}>
            <div className="next-image-preview" style={{ backgroundImage: `url(${images[0]})` }}></div>
            <div className="next-image-text">Swipe right for next image</div>
          </div>
        )}
        <div className="arrow-buttons">
          <button className="arrow left-arrow" style={{ display: 'none' }} onClick={scrollToLeft}>&#8592;</button>
          <button className="arrow right-arrow" onClick={scrollToRight}>&#8594;</button>
        </div>
      </div>
      {images.map((image, index) => (
        <div className="section" key={index} style={{ backgroundImage: `url(${image})` }}>
          {index < images.length - 1 && (
            <div className="next-image-overlay" onClick={scrollToRight}>
              <div className="next-image-preview" style={{ backgroundImage: `url(${images[index + 1]})` }}></div>
              <div className="next-image-text">Swipe right for next image</div>
            </div>
          )}
          <div className="arrow-buttons" style={{display:'none'}}>
            <button className="arrow left-arrow" onClick={scrollToLeft}>&#8592;</button>
            <button className="arrow right-arrow" style={{ display: index > images.length - 2 ? 'none' : 'block' }} onClick={scrollToRight}>&#8594;</button>
          </div>
          <ThumbnailNavigation images={images} activeIndex={activeIndex} onThumbnailClick={handleThumbnailClick} />
        </div>
      ))}
    </div>
  );
}
